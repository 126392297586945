// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

body {
  color: var(--color);
  background: var(--ion-background-color);
  font-weight: var(--ion-font-size) !important;
}
a * {
  pointer-events: none;
}
@font-face {
  font-family: "Open Sans";
  src: url("../assets/fonts/OpenSans-Bold.eot");
  src: url("../assets/fonts/OpenSans-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/OpenSans-Bold.woff2") format("woff2"),
    url("../assets/fonts/OpenSans-Bold.woff") format("woff"),
    url("../assets/fonts/OpenSans-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("../assets/fonts/OpenSans-Light.eot");
  src: url("../assets/fonts/OpenSans-Light.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/OpenSans-Light.woff2") format("woff2"),
    url("../assets/fonts/OpenSans-Light.woff") format("woff"),
    url("../assets/fonts/OpenSans-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("../assets/fonts/OpenSans-Regular.eot");
  src: url("../assets/fonts/OpenSans-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/OpenSans-Regular.woff2") format("woff2"),
    url("../assets/fonts/OpenSans-Regular.woff") format("woff"),
    url("../assets/fonts/OpenSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@media (prefers-color-scheme: dark) {
  //   /*
  //    * Dark Colors
  //    * -------------------------------------------
  //    */

  //   body {
  //     --ion-color-primary: #428cff;
  //     --ion-color-primary-rgb: 66,140,255;
  //     --ion-color-primary-contrast: #ffffff;
  //     --ion-color-primary-contrast-rgb: 255,255,255;
  //     --ion-color-primary-shade: #3a7be0;
  //     --ion-color-primary-tint: #5598ff;

  //     --ion-color-secondary: #50c8ff;
  //     --ion-color-secondary-rgb: 80,200,255;
  //     --ion-color-secondary-contrast: #ffffff;
  //     --ion-color-secondary-contrast-rgb: 255,255,255;
  //     --ion-color-secondary-shade: #46b0e0;
  //     --ion-color-secondary-tint: #62ceff;

  //     --ion-color-tertiary: #6a64ff;
  //     --ion-color-tertiary-rgb: 106,100,255;
  //     --ion-color-tertiary-contrast: #ffffff;
  //     --ion-color-tertiary-contrast-rgb: 255,255,255;
  //     --ion-color-tertiary-shade: #5d58e0;
  //     --ion-color-tertiary-tint: #7974ff;

  //     --ion-color-success: #2fdf75;
  //     --ion-color-success-rgb: 47,223,117;
  //     --ion-color-success-contrast: #000000;
  //     --ion-color-success-contrast-rgb: 0,0,0;
  //     --ion-color-success-shade: #29c467;
  //     --ion-color-success-tint: #44e283;

  //     --ion-color-warning: #ffd534;
  //     --ion-color-warning-rgb: 255,213,52;
  //     --ion-color-warning-contrast: #000000;
  //     --ion-color-warning-contrast-rgb: 0,0,0;
  //     --ion-color-warning-shade: #e0bb2e;
  //     --ion-color-warning-tint: #ffd948;

  //     --ion-color-danger: #ff4961;
  //     --ion-color-danger-rgb: 255,73,97;
  //     --ion-color-danger-contrast: #ffffff;
  //     --ion-color-danger-contrast-rgb: 255,255,255;
  //     --ion-color-danger-shade: #e04055;
  //     --ion-color-danger-tint: #ff5b71;

  //     --ion-color-dark: #f4f5f8;
  //     --ion-color-dark-rgb: 244,245,248;
  //     --ion-color-dark-contrast: #000000;
  //     --ion-color-dark-contrast-rgb: 0,0,0;
  //     --ion-color-dark-shade: #d7d8da;
  //     --ion-color-dark-tint: #f5f6f9;

  //     --ion-color-medium: #989aa2;
  //     --ion-color-medium-rgb: 152,154,162;
  //     --ion-color-medium-contrast: #000000;
  //     --ion-color-medium-contrast-rgb: 0,0,0;
  //     --ion-color-medium-shade: #86888f;
  //     --ion-color-medium-tint: #a2a4ab;

  //     --ion-color-light: #222428;
  //     --ion-color-light-rgb: 34,36,40;
  //     --ion-color-light-contrast: #ffffff;
  //     --ion-color-light-contrast-rgb: 255,255,255;
  //     --ion-color-light-shade: #1e2023;
  //     --ion-color-light-tint: #383a3e;
  //   }

  //   /*
  //    * iOS Dark Theme
  //    * -------------------------------------------
  //    */

  //   .ios body {
  //     --ion-background-color: #000000;
  //     --ion-background-color-rgb: 0,0,0;

  //     --ion-text-color: #ffffff;
  //     --ion-text-color-rgb: 255,255,255;

  //     --ion-color-step-50: #0d0d0d;
  //     --ion-color-step-100: #1a1a1a;
  //     --ion-color-step-150: #262626;
  //     --ion-color-step-200: #333333;
  //     --ion-color-step-250: #404040;
  //     --ion-color-step-300: #4d4d4d;
  //     --ion-color-step-350: #595959;
  //     --ion-color-step-400: #666666;
  //     --ion-color-step-450: #737373;
  //     --ion-color-step-500: #808080;
  //     --ion-color-step-550: #8c8c8c;
  //     --ion-color-step-600: #999999;
  //     --ion-color-step-650: #a6a6a6;
  //     --ion-color-step-700: #b3b3b3;
  //     --ion-color-step-750: #bfbfbf;
  //     --ion-color-step-800: #cccccc;
  //     --ion-color-step-850: #d9d9d9;
  //     --ion-color-step-900: #e6e6e6;
  //     --ion-color-step-950: #f2f2f2;

  //     --ion-item-background: #000000;

  //     --ion-card-background: #1c1c1d;
  //   }

  //   .ios ion-modal {
  //     --ion-background-color: var(--ion-color-step-100);
  //     --ion-toolbar-background: var(--ion-color-step-150);
  //     --ion-toolbar-border-color: var(--ion-color-step-250);
  //   }

  //   /*
  //    * Material Design Dark Theme
  //    * -------------------------------------------
  //    */

  //   .md body {
  //     --ion-background-color: #121212;
  //     --ion-background-color-rgb: 18,18,18;

  //     --ion-text-color: #ffffff;
  //     --ion-text-color-rgb: 255,255,255;

  //     --ion-border-color: #222222;

  //     --ion-color-step-50: #1e1e1e;
  //     --ion-color-step-100: #2a2a2a;
  //     --ion-color-step-150: #363636;
  //     --ion-color-step-200: #414141;
  //     --ion-color-step-250: #4d4d4d;
  //     --ion-color-step-300: #595959;
  //     --ion-color-step-350: #656565;
  //     --ion-color-step-400: #717171;
  //     --ion-color-step-450: #7d7d7d;
  //     --ion-color-step-500: #898989;
  //     --ion-color-step-550: #949494;
  //     --ion-color-step-600: #a0a0a0;
  //     --ion-color-step-650: #acacac;
  //     --ion-color-step-700: #b8b8b8;
  //     --ion-color-step-750: #c4c4c4;
  //     --ion-color-step-800: #d0d0d0;
  //     --ion-color-step-850: #dbdbdb;
  //     --ion-color-step-900: #e7e7e7;
  //     --ion-color-step-950: #f3f3f3;

  //     --ion-item-background: #1e1e1e;

  //     --ion-toolbar-background: #1f1f1f;

  //     --ion-tab-bar-background: #1f1f1f;

  //     --ion-card-background: #1e1e1e;
  //   }
}
ion-thumbnail {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    width: calc(100%) !important;
    height: 100% !important;
    background-color: #edf3f5;
    top: 0;
    left: 0;
    z-index: -1;
  }
  swiper-slide & {
    &:before {
      background-color: #dcdddd;
    }
  }
}
ion-content .centerInView,
ion-content ion-spinner {
  /* position: absolute;
  left: 50%;
  width: 90%;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);*/
  // width: 100%;
  font-size: 14px;
  color: #283a41 !important;
  margin-top: 20px !important;
  text-align: center;
  margin-left: auto !important;
  display: block;
  margin-right: auto !important;
  margin-bottom: 20px !important;
}
ion-list {
  background: transparent !important;
}
ion-item {
  --background: transparent;
  --detail-icon-color: #283a41;
  --detail-icon-opacity: 1;
}
ion-content * {
  --inner-padding-start: 0;
  --padding-start: 0;
  --inner-padding-end: 0;
  --padding-end: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  margin: 0 !important;
  padding: 0 !important;
  --min-height: 0 !important;
  font-weight: 600 !important;
  letter-spacing: 0 !important;
  --box-shadow: none !important;
  text-transform: initial !important;
  outline: none;
}
h1,
h2,
h3,
h4,
h5,
h6,
ion-toolbar {
  --ion-font-size: 600 !important;
  color: #283a41 !important;
}
ion-header ion-toolbar:first-of-type {
  padding-top: var(--ion-safe-area-top, 0) !important;
}
ion-content ion-header ion-toolbar:first-of-type {
  padding-top: var(--ion-safe-area-top, 0);
}
ion-modal ion-header ion-toolbar:first-of-type {
  padding-top: var(--ion-safe-area-top, 0);
}
.shadow {
  box-shadow: 0px 10px 28px rgba(125, 125, 125, 0.25);
}
ion-skeleton-text {
  height: 100%;
  border-radius: 20px;
}
$customFont: ("10", "12", "14", "16", "18", "20", "22", "24", "26");
@each $font in $customFont {
  .font-#{$font},
  .font-#{$font} * {
    font-size: #{$font}px !important;
  }
}
.capitalLetter {
  text-transform: capitalize !important;
}
$customSize: ("400", "600", "700", "800");
@each $size in $customSize {
  .size-#{$size},
  .size-#{$size} * {
    font-weight: #{$size} !important;
  }
}
.opacity-0 {
  opacity: 0;
}
.opacity-50 {
  opacity: 0.5;
}
.opacity-57 {
  opacity: 0.57;
}
.opacity-43 {
  opacity: 0.43;
}
.opacity-70 {
  opacity: 0.7;
}
.opacity-80 {
  opacity: 0.8;
}
.opacity-transition {
  transition: opacity 0.3s ease-out;
}
.opacity-show {
  opacity: 1 !important;
}
.opacity-hide {
  opacity: 0 !important;
}

$customFont: ("10", "12", "14", "16", "18", "20", "22", "24", "26");
@each $font in $customFont {
  .font-#{$font},
  .font-#{$font} * {
    font-size: #{$font}px !important;
  }
}
.capitalLetter {
  text-transform: capitalize !important;
}
$customSize: ("400", "600", "700", "800");
@each $size in $customSize {
  .size-#{$size},
  .size-#{$size} * {
    font-weight: #{$size} !important;
  }
}
ion-button,
ion-toolbar[routerlink],
ion-title[routerlink],
swiper-slide[routerlink],
ion-item[routerlink],
ion-toolbar[ng-reflect-router-link],
ion-title[ng-reflect-router-link],
swiper-slide[ng-reflect-router-link],
ion-item[ng-reflect-router-link],
.hover {
  transition: opacity 0.3s ease-out !important;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
}
ion-button {
  font-size: 16px;
  height: 52px;
  font-weight: 500 !important;
  &.button-block {
    --border-radius: 10px;
  }
  &.waitingRequest {
    opacity: 0.5;
    pointer-events: none;
  }
}
ion-select,
ion-textarea,
ion-input {
  --color: var(--ion-color-step-50) !important;
  --placeholder-color: var(--ion-color-step-700) !important;
  --placeholder-opacity: 1 !important;
  margin-top: 7px !important;
  font-size: 12px !important;
  margin-bottom: 10px !important;
  --border-radius: 8px;

  textarea,
  input {
    background: #fff !important;
    height: 44px;
    border: 2px solid transparent !important;
    padding: 0 16px !important;
    border-radius: 8px;
    overflow: hidden;
    &:focus {
      border: 2px solid var(--ion-color-pink) !important;
    }
    .ion-dirty.ion-invalid & {
      // border: 2px solid #ff1f1f !important;
    }
  }
  textarea {
    padding: 10px 16px !important;
    height: 124px;
  }
  // & + ion-icon {
  //   position: absolute;
  //   right: 8px;
  //   bottom: 8px;
  //   z-index: 2;
  //   width: 20px;
  // }
}
ion-select {
  background: #fff !important;
  height: 40px;
  border: 2px solid transparent !important;
  padding: 0 16px !important;
  border-radius: 8px;
}
.bkgradiant {
  --background: radial-gradient(
    116.99% 133.82% at 62.8% -21.64%,
    #0693c6 0%,
    #01688d 67.64%,
    #006184 100%
  );
}
swiper-container {
  width: calc(100% - 8px);
  overflow: visible !important;
}
ion-content {
  --padding-bottom: 44px;
  //--background: transparent;
  .wrap {
    //background: url(/assets/REMOVE/login.png) no-repeat center -40px !important;
    height: 100%;
  }
}
ion-modal,
ion-content {
  ion-title {
    position: static;
    text-align: left;
    padding-left: 0 !important;
    font-size: 20px;
    line-height: 27px;
    margin-top: 7px !important;
    margin-bottom: 7px !important;
  }
}
ion-toolbar {
  background: transparent !important;
  --background: transparent !important;
  --border-width: 0 !important;
}
.wrap {
  max-width: 500px;
  margin: 0 auto !important;
  padding: 22px 16px !important;
}
.wrap3 {
  max-width: 500px;
  padding: 22px 16px !important;
  margin: 0 auto !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrap2 {
  max-width: 500px;
  margin: 0 auto !important;
  padding: 0 34px !important;
}

.list {
  margin-bottom: 24px !important;
  ion-card,
  swiper-slide,
  ion-item {
    box-shadow: none;
    padding: 6px 8px !important;
    background: #fff;
    border-radius: 12px;
    --border-color: transparent;
    margin-bottom: 8px !important;
    overflow: hidden;
    ion-thumbnail {
      height: 75px !important;
      width: 75px !important;
      border-radius: 10px;
      overflow: hidden;
      margin-right: 16px !important;
      &.planning_master_plan {
        background-color: #00445c;
      }
      img.svg {
        object-fit: none;
      }
      ion-icon {
        font-size: 42px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #006184;
      }
    }
    ion-label {
      height: 100%;
      display: grid !important;
      align-content: space-between;
      h2 {
        padding-top: 4px !important;
        font-size: 16px;
        line-height: 22px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        padding-right: 30px !important;
      }
      p {
        color: #a6b8be;
        font-size: 12px;
        line-height: 16px;
        padding-bottom: 4px !important;
        font-weight: 600 !important;
      }
    }
    app-favorite ion-button {
      position: absolute;
      right: 4px;
      width: 16px;
      top: -10px;
    }
    ion-button.go {
      position: absolute;
      right: 10px;
      bottom: 3px;

      justify-content: end;
      float: right;
      width: 45px !important;
    }
  }
  ion-card,
  swiper-slide {
    display: block !important;
    padding: 0 !important;
    padding-right: 16px !important;
    background: transparent;
    ion-label {
      padding: 30px 20px 13px !important;
      text-align: left;
      margin-top: -17px !important;
      border-radius: 12px;
      background: #fff;
    }
    h4 {
      color: #a6b8be;
      font-size: 12px;
      line-height: 16px;
      padding-bottom: 4px !important;
    }
    h3 {
      font-size: 18px !important;
      padding-top: 1px !important;
      font-size: 16px;
      line-height: 22px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      height: 47px;
      margin-bottom: 23px !important;
    }
    ion-thumbnail {
      height: 232px !important;
      overflow: hidden;
      border-radius: 12px;
      display: block;
      object-fit: cover;
      width: 100% !important;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  &.slim {
    ion-item {
      padding: 12px 12px 7px 18px !important;
      margin-bottom: 6px !important;
      ion-label {
        border-radius: 0;
      }
      ion-label h2 {
        -webkit-line-clamp: 1;
        padding-right: 10px !important;
      }
      ion-thumbnail {
        height: 50px !important;
        width: 50px !important;
        margin-right: 0 !important;
        background: #edf3f5;
        border-radius: 12px;
        position: relative;
        img,
        ion-icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 22px;
        }
      }
    }
    ion-card,
    swiper-slide {
      text-align: center;
      border-radius: 0;
      ion-item {
        background: transparent;
      }
      ion-thumbnail {
        height: 88px !important;
        width: 88px !important;
        border-radius: 50%;
        margin: 15px auto !important;
        padding: 12px !important;
        overflow: hidden;
        background: #edf3f5 !important;
        position: relative;
        img {
          object-fit: scale-down;
          background: #01b6d1;
          border-radius: 50%;
          overflow: hidden;
          height: 70%;
          width: 70%;
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
      ion-label {
        text-align: center;
        margin-top: 0 !important;
        padding: 30px 10px 13px !important;
        margin-top: -120px !important;
        padding-top: 151px !important;
        border-radius: 12px;
      }
      h3 {
        -webkit-line-clamp: 3;
        font-size: 16px !important;
        line-height: 22px !important;
        margin-top: -42px !important;
        height: 78px;
        margin-bottom: 0 !important;
      }
    }
  }
}
.link {
  font-size: 13px;
  padding-right: 9px !important;
  text-align: right !important;
  display: grid;
  justify-content: start;

  background: url(/assets/icon/arrow.svg) no-repeat right center / 5px auto !important;
  color: #01b6d1;
  height: 15px;
  width: 72px;
  --background: transparent;
}

ion-segment {
  height: 45px;
  --background: #fff;
  --background: transparent;
  border: 0;
  padding: 4px 4px !important;
  border-radius: 15px;
  --border-radius: 12px;
  margin-bottom: 20px !important;
  ion-segment-button {
    --border-radius: 12px;
    --background: #fff;
    --background: transparent;
    --indicator-color: #01b6d1;
    --border-width: 0;
    height: calc(100% + 1px);
    width: calc(100% + 11px) !important;
    --color: #a6b8be;
    --color-checked: #fff;
    --indicator-box-shadow: none;
    min-width: auto;
    padding: 0 10px !important;
  }

  .tags {
    margin-bottom: 0 !important;
    white-space: nowrap;
    background: transparent;
    border-radius: 0;
    --border-radius: 0;
    ion-segment-button {
      height: 30px;
      --padding-start: 13px !important;
      --padding-end: 13px !important;
      font-size: 12px;
      border-radius: 13px !important;
      margin-right: 1px !important;

      --background: transparent;
      --background-activated: #01b5d180;
      --background-focused: #01b5d180;
      --background-hover: #01b5d180;
      --color-activated: #fff;
      --color-focused: #fff;
      --color-hover: #fff;
      --color: #a6b8be;
      min-width: auto;
      --indicator-color: #01b6d1;
    }
  }
}
